
  export default {
    name: 'WCol',
    components: {},
    props: {
      col: {
        type: String,
        default: '12'
      },
      xs: String,
      sm: String,
      md: String,
      lg: String,
      xl: String
    },
    data () {
      return {}
    },
    computed: {
      classString (vm) {
        return {
          WCol: true,
          [`col-${vm.col}`]: true,
          [`col-xs-${vm.xs || vm.col}`]: Boolean(vm.xs),
          [`col-sm-${vm.sm || vm.col}`]: Boolean(vm.sm),
          [`col-md-${vm.md || vm.col}`]: Boolean(vm.md),
          [`col-lg-${vm.lg || vm.col}`]: Boolean(vm.lg),
          [`col-xl-${vm.xl || vm.col}`]: Boolean(vm.xl)
        }
      }
    },
    methods: {}
  }
