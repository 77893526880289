export default (el, binding) => {
  if ('svg' in binding.modifiers) {
    el.style.maskImage = `url(${binding.value})`
    el.style.webkitMask = `url(${binding.value})`
    el.style.maskSize = 'contain'
    el.style.webkitMaskSize = 'contain'
    el.style.maskRepeat = 'no-repeat'
    el.style.webkitMaskRepeat = 'no-repeat'
    el.style.maskPosition = 'center'
    el.style.webkitMaskPosition = 'center'
  } else {
    el.className = el.className.replace(/ *\b\S*?icon_\S*\b/g, '')
    el.className = `${el.className} icon_${binding.value}`
  }
}
