
  import { mapGetters } from 'vuex'
  import PageSwitch from '~/components/layout/pageSwitch/PageSwitch'
  import { localizeRouteName } from '~/services/RouterService'
  export default {
    name: 'MTGSinglesPageSwitch',
    components: { PageSwitch },
    props: {},
    data () {
      return {
        active: 'singles_guide_mtg_sell',
        items: [
          {
            id: 'singles_guide_mtg_sell',
            title: 'product_singles'
          },
          {
            id: 'singles_guide_mtg_bulk_purchase',
            title: 'page_mtg_singles_bulk_purchase',
            new: true
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        language: 'general/getLanguage',
      })
    },
    watch: {
      $route: {
        immediate: true,
        handler (route) {
          this.active = route.name.split('__')[0]
        }
      }
    },
    beforeMount() {
      if(window.innerWidth < 768) {
        this.items = [
          {
            id: 'singles_guide_mtg_sell',
            title: 'product_singles_short'
          },
          {
            id: 'singles_guide_mtg_bulk_purchase',
            title: 'page_mtg_singles_bulk_purchase_short',
            new: true
          }
        ]
      }
    },
    methods: {
      async setSection (value) {
        try {
          await this.$router.push({ name: localizeRouteName(value, this.language) })
        } catch (e) {
          this.$log.error(e)
        }
      }
    }
  }
