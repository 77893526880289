
  export default {
    name: 'ContentWithBackground',
    functional: true,
    props: {
      title: {
        type: String
      },
      backgroundUrl: {
        type: String
      },
      link: String,
      linkExternal: Boolean,
      navigationPage: String
    },
    componentType (link, linkExternal, navPage) {
      return (link || navPage) ? (linkExternal ? 'a' : 'nuxt-link') : 'div'
    },
    wrapperConfig (link, linkExternal, navPage) {
      const config = {}

      if (link) {
        if (linkExternal) {
          config.href = link
          config.target = '_blank'
        } else {
          config.to = link
        }
      } else if (navPage) {
        config.to = navPage
      }

      return config
    }
  }
