
  import BannerSwiper from '@/components/general/swiper/BannerSwiper'
  import ContentWithBackground from '@/components/general/elements/ContentWithBackground'

  export default {
    name: 'BannersLayout',
    functional: true,
    components: { ContentWithBackground, BannerSwiper },
    inject: ['getLoc'],
    props: {
      mainBanners: {
        type: Array,
        default: () => []
      },
      sideBanners: {
        type: Array,
        default: () => []
      }
    }
  }
