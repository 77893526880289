
  import isEmpty from 'lodash/isEmpty'
  import Button from '@/components/general/elements/Button'

  import Row from '@/components/layout/Row'
  import ButtonRemove from '~/components/general/elements/ButtonRemove'
  import { FormField } from '~/structures/FormField'

  export default {
    name: 'FileInput',
    components: { ButtonRemove, Row, Button },
    props: {
      field: {
        type: FormField,
        required: true
      },
      value: {},
      align: {
        type: String,
        default: 'start'
      }
    },
    data () {
      return {
        innerValue: null
      }
    },
    computed: {
      fileName (vm) {
        return vm.innerValue ? vm.innerValue.name : vm.value ? vm.value.name : ''
      }
    },
    methods: {
      browse () {
        this.$refs.input.click()
      },
      input (e) {
        let value = null

        if (!isEmpty(e.target.files)) {
          value = e.target.files[0]
        }

        this.innerValue = value

        this.$emit('input', value)
      },
      removeFile() {
        this.innerValue = null
        this.$refs.input.value = null
        this.$emit('input', null)
      }
    }
  }
