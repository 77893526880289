
  import PageSwitch from '@/components/layout/pageSwitch/PageSwitch'

  import { BusEvents, EventBus } from '@/services/eventBus/EventBus'

  export default {
    name: 'SearchPageSwitch',
    components: { PageSwitch },
    props: {},
    data () {
      return {
        active: EventBus.search.section,
        items: {
          products: {
            id: 'products',
            title: 'product_products',
            subtitle: EventBus.search.count.products,
            disabled: !EventBus.search.sectionsAvailable.products
          },
          loose_cards: {
            id: 'loose_cards',
            title: 'product_singles',
            subtitle: EventBus.search.count.loose_cards,
            disabled: !EventBus.search.sectionsAvailable.loose_cards
          },
          lorcana_singles: {
            id: 'lorcana_singles',
            title: 'product_singles_lorcana',
            subtitle: EventBus.search.count.lorcana_singles,
            disabled: !EventBus.search.sectionsAvailable.lorcana_singles
          }
        }
      }
    },
    created () {
      if(process.client) {
        EventBus.$on(BusEvents.search.countChange, this.onSearchCountChange)
        EventBus.$on(BusEvents.search.sectionChange, this.onSearchSectionChange)
        EventBus.$on(BusEvents.search.sectionAvailabilityChange, this.onSearchSectionAvailabilityChange)
      }
    },
    beforeDestroy () {
      EventBus.$off(BusEvents.search.countChange, this.onSearchCountChange)
      EventBus.$off(BusEvents.search.sectionChange, this.onSearchSectionChange)
      EventBus.$off(BusEvents.search.sectionAvailabilityChange, this.onSearchSectionAvailabilityChange)
    },
    methods: {
      onSearchCountChange () {
        this.items.products.subtitle = EventBus.search.count.products
        this.items.loose_cards.subtitle = EventBus.search.count.loose_cards
        this.items.lorcana_singles.subtitle = EventBus.search.count.lorcana_singles
      },
      onSearchSectionChange(/** @type {EventBus.SearchSection} */ section) {
        this.active = section
      },
      onSearchSectionAvailabilityChange(change) {
        this.items.products.disabled = !change.products
        this.items.loose_cards.disabled = !change.loose_cards
        this.items.lorcana_singles.disabled = !change.lorcana_singles
      },
      async setSection (value) {
        if (value === this.active) return

        this.active = value
        await this.$nextTick()
        // await this.setSectionQuery()
        EventBus.setActiveSearchSection(value)
      },
      async setSectionQuery () {
        const routeName = this.$route.name
        if (routeName.includes('search')) {
          await this.$router.replace({
            name: routeName,
            query: {
              ...this.$route.query,
              section: this.active
            }
          })
        }
      }
    }
  }
