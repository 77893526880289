

  import { FormField } from '~/structures/FormField'

  export default {
    name: 'TextInput',
    functional: true,
    components: {},
    props: {
      field: {
        type: FormField,
        default: () => new FormField({
          name: 'q'
        })
      },
      value: {
        required: true
      },
      autocomplete: String,
      white: {
        type: Boolean,
        default: false
      },
      noBorderLeft: Boolean,
      icon: String,
      dark: Boolean
    },
    getListeners (listeners = {}) {
      const newListeners = {
        ...listeners
      }

      if ('input' in listeners) {
        newListeners.input = function (e) {
          listeners.input(e.target.value)
        }
      }

      return newListeners
    }
  }
