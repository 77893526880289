import { firstLetterUppercase, roundToDecimals } from '@/services/Helpers'

export default {
  methods: {
    firstLetterUppercase (string) {
      return firstLetterUppercase(string)
    },
    roundToDecimals (number, decimals) {
      return roundToDecimals(number, decimals)
    }
  }
}
