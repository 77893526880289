
  export default {
    name: 'ButtonRemove',
    components: {},
    props: {
    },
    data () {
      return {}
    },
    methods: {

    }
  }
