
  import TextInput from '@/components/form/inputs/TextInput'
  import SelectInput from '@/components/form/inputs/SelectInput'
  import { FormField } from '~/structures/FormField'

  export default {
    name: 'PhoneInput',
    components: { TextInput, SelectInput },
    props: {
      field: {
        type: FormField,
        required: true
      },
      value: {
        required: true
      }
    },
    data () {
      const oThis = this

      return {
        code: oThis.value.code,
        number: oThis.value.number
      }
    },
    methods: {
      updateCode (value) {
        this.code = value
        this.update()
      },
      updateNumber (value) {
        this.number = value
        this.update()
      },
      update () {
        this.$emit('input', {
          code: this.code,
          number: this.number
        })
      }
    }
  }
