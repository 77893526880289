import { mapGetters } from 'vuex'
import { decodeLoc } from '~/structures/Localizable'

export default {
  computed: {
    ...mapGetters({
      localizableLanguage: 'general/getLanguage',
      localizableCurrency: 'general/getCurrencyLower'
    }),
    getLoc(vm) {
      return (values, language) => {
        return decodeLoc(values, language || vm.localizableLanguage)
      }
    },
    getLocCurrency(vm) {
      return (values, currency) => {
        return decodeLoc(values, currency || vm.localizableCurrency)
      }
    },
  }
}
