
  export default {
    name: 'ItemBubble',
    functional: true,
    props: {
      type: {
        type: String,
        required: true,
        validator: val => {
          return ['new', 'sale', 'sale-promo', 'presale', 'promo', 'hot', ''].includes(val)
        }
      },
      content: {
        type: String,
        required: true
      },
      xs: Boolean,
      sm: Boolean,
      lg: Boolean
    },
    classObject (type, xs, sm, lg, christmas) {
      let innerType = type

      if(christmas && ['sale', 'sale-promo'].includes(type)) {
        innerType = 'sale-christmas'
      }

      return {
        ItemBubble: true,
        [innerType]: true,
        [type]: true,
        xs,
        sm,
        lg
      }
    }
  }
