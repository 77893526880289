

  export default {
    name: 'ListLoadIndicator',
    functional: true,
    props: {
      loading: {
        type: Boolean,
        default: false
      }
    }
  }
