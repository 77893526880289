import Background from '@/configurations/directives/Background'
import BackgroundCentered from '@/configurations/directives/BackgroundCentered'
import BackgroundCover from '@/configurations/directives/BackgroundCover'
import ClickOutside from '@/configurations/directives/ClickOutside'
import Icon from '@/configurations/directives/Icon'

export const background = Background
export const backgroundCentered = BackgroundCentered
export const backgroundCover = BackgroundCover
export const clickOutside = ClickOutside
export const icon = Icon
