
  import filter from 'lodash/filter'
  import isEmpty from 'lodash/isEmpty'
  import last from 'lodash/last'
  import map from 'lodash/map'

  import { mapGetters } from 'vuex'
  import LocaleRefresh from '@/components/utils/LocaleRefresh'
  import NavigationPage from '@/database/models/NavigationPage'
  import { BusEvents, EventBus } from '@/services/eventBus/EventBus'
	import {executeGAPageView} from '~/services/AnalyticsService'
  import { waitTimeout } from "~/services/Helpers";

  export default {
    name: 'Breadcrumbs',
    components: { LocaleRefresh },
    inject: ['getLoc'],
    props: {},
    data () {
      return {}
    },
    head () {
      return {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: this.$t(this.lastRouteItemText),
        // all titles will be injected into this template
        titleTemplate: '%s | Najada.games',
        htmlAttrs: {
          lang: this.globalLanguageShortcut
        },
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: this.$t('seo_meta_description_default')
          },
          {
            hid: 'robots',
            name: 'robots',
            content: 'all'
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'website'
          },
          {
            hid: 'og:title',
            property: 'og:title',
            content: this.$t(this.lastRouteItemText)
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: this.$t('seo_meta_description_default')
          },
          {
            hid: 'og:site_name',
            property: 'og:site_name',
            content: 'Najáda'
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: `${this.globalBaseUrl}${this.$route.path}`
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: `${this.globalBaseUrl}/img/najada-social-banner.png`
          },
          {
            hid: 'og:image:width',
            property: 'og:image:width',
            content: '1080'
          },
          {
            hid: 'og:image:height',
            property: 'og:image:height',
            content: '1080'
          },
        ],
        link: [
          {
            rel: 'canonical',
            href: `${this.globalBaseUrl}${this.$route.path}`
          }
        ]
      }
    },
    created () {
      if(process.client) {
        EventBus.$on(BusEvents.breadcrumbs.remapRequst, this.remapBreadcrumbs)
      }
    },
    beforeDestroy () {
      EventBus.$off(BusEvents.breadcrumbs.remapRequst, this.remapBreadcrumbs)
    },
    methods: {
      getPath (route) {
        if ('name' in route) {
          return { name: route.name }
        } else {
          return { path: route.path || '/' }
        }
      },
      remapBreadcrumbs () {
        // Breadcrumbs are registered in navigation rules
        const routePathFragments = this.$route.path.split('/')
        routePathFragments.shift()
        let sideNavigationObject = this.getMenu(routePathFragments[0])
        sideNavigationObject = sideNavigationObject ? new NavigationPage(sideNavigationObject) : sideNavigationObject
        const routerBasedFragments = ['blog']

        if (sideNavigationObject && !routerBasedFragments.includes(routePathFragments[0])) {
          let breadcrumbs = NavigationPage.getRouteArray(sideNavigationObject, routePathFragments)
          breadcrumbs = map(breadcrumbs, item => {
            return { path: this.getLoc(item.fullUrlLoc), label: this.getLoc(item.titleLoc) || item.title }
          })
          EventBus.setBreadcrumbs(breadcrumbs)
        } else if (this.$route.matched.length > 0) {
          let breadcrumbs = filter(this.$route.matched, route => route.name !== 'home')
          breadcrumbs = map(breadcrumbs, route => {
            const label = 'meta' in route && 'breadcrumb' in route.meta && 'label' in route.meta.breadcrumb ? route.meta.breadcrumb.label : null
            return { path: route.path, label }
          })
          breadcrumbs = filter(breadcrumbs, breadcrumb => {
            return !isEmpty(breadcrumb.label)
          })

          EventBus.setBreadcrumbs(breadcrumbs)
        }
      }
    },
    computed: {
      ...mapGetters({
        getMenu: 'menu/getMenu',
      }),
      isHomepage (vm) {
        return vm.$route.name === 'home'
      },
      routes () {
        return EventBus.breadcrumbs.items
      },
      lastRouteItemText (vm) {
        if (isEmpty(vm.routes)) {
          return 'eShop'
        } else {
          return last(vm.routes).label
        }
      }
    },
    watch: {
      $route: {
        immediate: true,
        async handler () {
          this.remapBreadcrumbs()

          if(process.client) {
            await this.$nextTick()
            await waitTimeout(500)
            executeGAPageView({
              title: this.$t(this.lastRouteItemText),
              path: this.$route.path
            })
          }
        }
      }
    }
  }
