
  import ItemBubble from '~/components/general/elements/Product/ItemBubble'
  export default {
    name: 'PageSwitchItem',
    components: { ItemBubble },
    props: {
      id: [Number, String],
      title: {
        type: String
      },
      subtitle: {
        type: [String, Number]
      },
      isActive: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      new: Boolean
    },

    onClick (id, disabled, listeners) {
      if (!disabled) {
        listeners.click(id)
      }
    }
  }
