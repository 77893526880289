
  export default {
    name: 'ScreenTooltipDisplay',
    props: {},
    data() {
      return {
        show: false,
        top: 0,
        left: 0,
        title: null,
        content: null,
        maxWidth: null,
        bgColor: 'white',

        component: {
          display: null,
          binders: {},
          listeners: {},
        }
      }
    },
    computed: {
      styles(vm) {
        const maxWidth = vm.maxWidth
        const top = vm.top
        const left = vm.left
        const styles = {top, left}

        if (maxWidth) {
          styles.maxWidth = maxWidth
        }

        return styles
      },
      hasComponentDisplay(vm) {
        return vm.component.display !== null
      }
    },
    mounted() {
      this.$tooltip.eventBus.$on('show', this.showTooltip)
      this.$tooltip.eventBus.$on('hide', this.hideTooltip)
    },
    beforeDestroy() {
      this.$tooltip.eventBus.$off('show', this.showTooltip)
      this.$tooltip.eventBus.$off('hide', this.hideTooltip)
    },
    methods: {
      onClickOutside() {
        if (this.show) {
          this.hideTooltip()
        }
      },
      showTooltip({
                    title = null,
                    content = null,
                    x = 0,
                    y = 0,
                    component = null,
                    binders,
                    listeners,
                    maxWidth,
                    bgColor
                  }) {
        if (component) {
          this.showTooltipComponent({
            component,
            binders,
            listeners,
            maxWidth
          })
        }

        this.top = `${y}px`
        this.bottom = `${y}px`
        this.left = `${x}px`
        this.title = title
        this.content = content
        this.bgColor = bgColor
        this.show = true
      },

      showTooltipComponent({component, binders = {}, listeners = {}, maxWidth = null}) {
        this.component.display = component
        this.component.binders = binders
        this.component.listeners = listeners
        this.maxWidth = maxWidth
        this.show = true
      },

      hideTooltip() {
        this.show = false
        this.maxWidth = null

        if (this.hasComponentDisplay) {
          this.component.display = null
          this.component.binders = {}
          this.component.listeners = {}
        }

      }
    }
  }
