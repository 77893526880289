
  import TextInput from '@/components/form/inputs/TextInput'
  import Checkbox from '@/components/form/inputs/Checkbox'
  import SelectInput from '@/components/form/inputs/SelectInput'
  import PhoneInput from '@/components/form/inputs/PhoneInput'

  import RadioInput from '@/components/form/inputs/RadioInput'
  import TextareaInput from '@/components/form/inputs/TextareaInput'
  import Asterisk from '@/components/general/elements/Asterisk'
  import FileInput from '@/components/form/inputs/FileInput'
  import RatingInput from '~/components/form/inputs/RatingInput.vue'
  import { FormField } from '~/structures/FormField'

  export default {
    name: 'FormInput',
    components: { RatingInput, FileInput, Asterisk, TextareaInput, RadioInput, TextInput, Checkbox, SelectInput, PhoneInput },
    props: {
      field: {
        type: FormField,
        required: true
      },
      value: {
        required: true
      },
      state: {
        type: [Boolean, String],
        default: false
      },
      showState: Boolean,
      align: String,
    },
    computed: {
      id (vm) {
        return vm.field.name
      },
      stateIcon (vm) {
        if (vm.state) {
          return 'warning'
        }

        return 'check-rounded'
      }
    },
    methods: {
      updateInput (value) {
        this.$emit('input', value)
      }
    }
  }
