

  import { FormField } from '~/structures/FormField'

  export default {
    name: 'Check',
    functional: true,
    components: {},
    props: {
      field: {
        type: FormField
      },
      value: {
        required: true
      },
      isDark: Boolean,
      isLighter: Boolean
    },
    getListeners (listeners, value) {
      const newListeners = {}

      if ('input' in listeners) {
        newListeners.click = function () {
          listeners.input(!value)
        }
      }

      return newListeners
    },
    getId (field) {
      return field ? field.name : 'checkbox'
    }
  }
