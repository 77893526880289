import { render, staticRenderFns } from "./Asterisk.vue?vue&type=template&id=5571ba36&scoped=true"
import script from "./Asterisk.vue?vue&type=script&lang=js"
export * from "./Asterisk.vue?vue&type=script&lang=js"
import style0 from "./Asterisk.vue?vue&type=style&index=0&id=5571ba36&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5571ba36",
  null
  
)

export default component.exports