export default {
  props: {
    value: {},
    lazy: Boolean
  },
  data () {
    return {
      innerValue: this.value
    }
  },
  methods: {
    inputData () {
      return this.innerValue
    },
    valueChanged (newValue) {
      if (this.lazy) {
        this.innerValue = newValue
      } else {
        this.$emit('change', newValue)
      }
    }
  },
  watch: {
    value: {
      intermediate: true,
      handler (newValue) {
        this.innerValue = newValue
      }
    }
  }
}
