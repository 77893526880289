import isString from 'lodash/isString'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'

export default (el, binding) => {
  let background = null

  if (isString(binding.value)) {
    background = binding.value
  } else if (isArray(binding.value) && !isEmpty(binding.value)) {
    if (window.matchMedia('(max-width: 767px)').matches) {
      background = binding.value[0]
    } else {
      background = binding.value[1]
    }
  }

  el.style.background = `url(${background})`
}
