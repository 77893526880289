
  import Button from '@/components/general/elements/Button'

  import { processTranslationData } from '@/structures/Localization'
  import { runTask } from '~/services/network/utils/axios'
  import { getTranslations } from '~/workers/network.worker'

  export default {
    name: 'LocaleRefresh',
    components: { Button },
    props: {},
    data () {
      return {
        isLoading: false
      }
    },
    methods: {
      async fetch () {
        this.isLoading = true
        await this.$nextTick()

        try {
          /** @type {FetchResult} */
          const response = await runTask(getTranslations())

          const translationsObject = processTranslationData(response.data)
          const langKeys = Object.keys(translationsObject)

          for (let i = 0; i < langKeys.length; i++) {
            const key = langKeys[i]
            this.$i18n.setLocaleMessage(key, translationsObject[key])
          }
        } catch (e) {
          this.$log.error(e)
        } finally {
          this.isLoading = false
        }
      }
    }
  }
