
  import { mapActions, mapGetters } from 'vuex'
  import GeneralForm from '@/components/form/GeneralForm'
  import ActionLink from '@/components/general/elements/ActionLink'

  import {FormField} from '@/structures/FormField'

  import FormSection from '@/structures/FormSection'
  import {required, minLength} from '@/structures/FormFieldValidator'
  import Button from '~/components/general/elements/Button'
  import Row from '~/components/layout/Row'

  export default {
    name: 'LoginForm',
    components: {
      Row,
      Button,
      GeneralForm,
      ActionLink
    },
    props: {
      message: String
    },
    data () {
      return {
        user: {
          email: '',
          password: ''
        },
        title: '',
        error: null,
        isLoading: false,
        isFBLoading: false,
        sections: [
          new FormSection({
            fields: [
              new FormField({
                name: 'email',
                type: 'text',
                inputmode: 'email',
                label: 'form_email_username',
                required: true,
                autocomplete: 'email',
                rules: [required]
              }),
              new FormField({
                name: 'password',
                autocomplete: 'current-password',
                type: 'password',
                label: 'form_password',
                required: true,
                rules: [required, minLength(8)]
              })
            ]
          })
        ]
      }
    },
    computed: {
      ...mapGetters({
        isAuthenticated: 'authentication/isAuthenticated',
        userFullName: 'authentication/userFullName',
        userHasUnfinishedRegistration: 'authentication/userHasUnfinishedRegistration'
      }),

      isInactiveAccount (vm) {
        return vm.error && vm.error.reason && vm.error.reason === 'inactive_account'
      }
    },
    methods: {
      ...mapActions({
        loginWithFB: 'authentication/socialLoginFacebook'
      }),
      async signInWithFacebook() {
        if(this.isFBLoading) return

        this.isFBLoading = true
        await this.$nextTick()

        try {
          await this.loginWithFB({appClientId: this.$config.facebook.appClientId})

          if(this.userHasUnfinishedRegistration) {
            this.$modal.showRegistrationAdditional()
          } else {
            this.$modal.hide()
          }
        } catch (e) {
          this.$log.error(e)
        } finally {
          this.isFBLoading = false
        }
      },
      showResendActivation () {
        this.$modal.showResendActivation()
      },
      switchToPasswordReset () {
        const oThis = this
        this.$modal.showPasswordResetRequest(function () {
          oThis.$modal.showMessageAlert({
            title: 'modal_title_password_change',
            message: 'password_change_request_follow_up'
          })
        })
      },
      switchToRegistration () {
        this.$modal.showRegistration()
      },
      async submit () {
        this.error = null
        this.isLoading = true
        await this.$nextTick()

        try {
          await this.$store.dispatch('authentication/login', this.user)
          await this.$store.dispatch('authentication/getShippingAddresses')
          this.$emit('success')
        } catch (e) {
          this.error = e
        } finally {
          this.isLoading = false
        }
      }
    }
  }
