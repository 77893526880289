
  import isArray from 'lodash/isArray'
  import isObject from 'lodash/isObject'
  import isString from 'lodash/isString'

  export default {
    name: 'Row',
    functional: true,
    components: {},
    props: {
      alignVertical: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'baseline', 'stretch'].includes(str)
      },
      alignVerticalXs: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'baseline', 'stretch'].includes(str)
      },
      alignVerticalSm: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'baseline', 'stretch'].includes(str)
      },
      alignVerticalMd: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'baseline', 'stretch'].includes(str)
      },
      alignVerticalLg: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'baseline', 'stretch'].includes(str)
      },
      alignVerticalXl: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'baseline', 'stretch'].includes(str)
      },
      alignHorizontal: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'between', 'around'].includes(str)
      },
      alignHorizontalXs: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'between', 'around'].includes(str)
      },
      alignHorizontalSm: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'between', 'around'].includes(str)
      },
      alignHorizontalMd: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'between', 'around'].includes(str)
      },
      alignHorizontalXl: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'between', 'around'].includes(str)
      },
      alignHorizontalLg: {
        type: String,
        validator: str => ['', 'start', 'end', 'center', 'between', 'around'].includes(str)
      },
      multiple: {
        type: Boolean,
        default: false
      },
      gutters: {
        type: Boolean,
        default: true
      }
    },

    getClassObject (props, staticClass, dynamicClass) {
      let dynamicClassString = isString(dynamicClass) ? dynamicClass : ''

      if(isArray(dynamicClass)) {
        dynamicClassString = dynamicClass.join(' ')
      }

      return {
        'row-wrapper': !props.multiple,
        'row-wrapper-multiple': props.multiple,
        [`align-items-${props.alignVertical}`]: props.alignVertical,
        [`align-items-xs-${props.alignVerticalXs}`]: props.alignVerticalXs,
        [`align-items-sm-${props.alignVerticalSm}`]: props.alignVerticalSm,
        [`align-items-md-${props.alignVerticalMd}`]: props.alignVerticalMd,
        [`align-items-lg-${props.alignVerticalLg}`]: props.alignVerticalLg,
        [`align-items-xl-${props.alignVerticalXl}`]: props.alignVerticalXl,
        [`justify-content-${props.alignHorizontal}`]: props.alignHorizontal,
        [`justify-content-xs-${props.alignHorizontalXs}`]: props.alignHorizontalXs,
        [`justify-content-sm-${props.alignHorizontalSm}`]: props.alignHorizontalSm,
        [`justify-content-md-${props.alignHorizontalMd}`]: props.alignHorizontalMd,
        [`justify-content-lg-${props.alignHorizontalLg}`]: props.alignHorizontalLg,
        [`justify-content-xl-${props.alignHorizontalXl}`]: props.alignHorizontalXl,
        gutted: props.gutters,
        [staticClass]: true,
        [dynamicClassString]: true,
        ...(isObject(dynamicClass) && {...dynamicClass})
      }
    }
  }
