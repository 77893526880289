import themeSettings from '@/settings'
import OS from '@/structures/OS'
import { daysString, items6thString, itemsString, nexts6thString, piecesString, thousandsSeparator } from '@/services/Helpers'

export default {
  data () {
    return {
      isStage: false,
      appVersion: themeSettings.version,
      captchaSiteKey: null,
      isTouchCapable: false
    }
  },
  computed: {
    globalLanguageShortcut (vm) {
      return vm.$store.getters['general/getLanguage']
    },
    globalCurrency (vm) {
      return vm.$t(`currency_${vm.globalCurrencyShortcutLower}`)
    },
    globalCurrencyShortcut (vm) {
      return vm.$store.getters['general/getCurrency']
    },
    globalCurrencyShortcutLower (vm) {
      return vm.$store.getters['general/getCurrencyLower']
    },
    globalBaseUrl (vm) {
      return `https://${vm.$config.domain}`
    }
  },
  methods: {
    pieceString (number, short = false) {
      return piecesString(number, short)
    },
    daysString (number) {
      return daysString(number)
    },
    itemsString (number) {
      return itemsString(number)
    },
    items6tgString (number) {
      return items6thString(number)
    },
    resultsString (number) {
      return itemsString(number)
    },
    nexts6thString(number) {
      return nexts6thString(number)
    },
    thousandSeparators (num) {
      return thousandsSeparator(num)
    },
    isMobile () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    },
    getOS () {
      const userAgent = window.navigator.userAgent
      const platform = window.navigator.platform
      const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
      const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
      const iosPlatforms = ['iPhone', 'iPad', 'iPod']
      let os = null

      if (macosPlatforms.includes(platform)) {
        os = OS.macOS
      } else if (iosPlatforms.includes(platform)) {
        os = OS.iOS
      } else if (windowsPlatforms.includes(platform)) {
        os = OS.windows
      } else if (/Android/.test(userAgent)) {
        os = OS.android
      } else if (!os && /Linux/.test(platform)) {
        os = OS.linux
      }

      return os
    },

    loadRecaptcha () {
      if(process.client) {
        const lang = this.$store.getters['general/getLanguage']

        if (lang === window.recaptchaLang) return

        window.recaptchaLang = lang
        const recaptchaContainer = document.getElementById('recaptchaContainer')
        // Get GoogleCaptcha iframe
        const script = document.createElement('script')
        script.setAttribute('src', `https://www.google.com/recaptcha/api.js?hl=${lang}&render=explicit`)

        recaptchaContainer.appendChild(script)
      }
    }
  },
  mounted () {
    this.isStage = this.$config?.isStage
    this.captchaSiteKey = this.$config?.recaptcha?.siteKey
    if(process.client) {
      this.isTouchCapable ='ontouchstart' in document.documentElement
    }
  }
}
