
  import PageSwitchItem from '@/components/layout/pageSwitch/PageSwitchItem'

  export default {
    name: 'PageSwitch',
    components: { PageSwitchItem },
    props: {
      items: {
        type: [Array, Object]
      },
      value: [Number, String]
    }
  }
