

  import { FormField } from '~/structures/FormField'

  export default {
    name: 'TextareaInput',
    components: {},
    props: {
      field: {
        type: FormField,
        required: true
      },
      value: {
        required: true
      }
    },
    data () {
      return {}
    },
    computed: {
      id (vm) {
        return vm.field.name
      }
    },
    methods: {
      update () {
        this.$emit('input', this.$refs.input.value)
      }
    }
  }
