

  import { FormField } from '~/structures/FormField'

  export default {
    name: 'SelectInput',
    components: {},
    props: {
      field: {
        type: FormField,
        required: true
      },
      value: {
        required: true
      },
      idAppend: String,
      autocomplete: String
    },
    data () {
      return {}
    },
    computed: {
      id (vm) {
        const ids = [vm.field.name]

        if (vm.idAppend) {
          ids.push(vm.idAppend)
        }

        return ids.join('-')
      }
    },
    methods: {
      update () {
        this.$emit('input', this.$refs.input.value)
      }
    }
  }
