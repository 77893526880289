
  import isObject from 'lodash/isObject';
  import isArray from 'lodash/isArray';

  export default {
    name: 'Column',
    functional: true,
    props: {
      alignVertical: {
        type: String,
        default: 'start',
        validator: str => ['', 'start', 'end', 'center', 'baseline', 'stretch'].includes(str)
      },
      alignHorizontal: {
        type: String,
        default: 'start',
        validator: str => ['', 'start', 'end', 'center', 'between', 'around'].includes(str)
      }
    },
    getClassObject(props, dynamicClass = {}, staticClass) {
      const vertical = props.alignVertical
      const horizontal = props.alignHorizontal
      const classString = staticClass || ''
      let dynamicClassObject = {}

      if (isArray(dynamicClass)) {
        dynamicClassObject[dynamicClass.join(' ')] = true
      } else if(isObject(dynamicClass)) {
        dynamicClassObject = dynamicClass
      }

      return {
        'column-wrapper': true,
        [classString]: true,
        [`align-items-${vertical}`]: vertical,
        [`justify-content-${horizontal}`]: horizontal,
        ...dynamicClassObject
      }
    }
  }
