export default {
  created () {
    const escapeHandler = (e) => {
      if (e.key === 'Escape') {
        this.escapeHandler()
      }
    }

    if(process.client) {
      document.addEventListener('keydown', escapeHandler)

      this.$once('hook:destroyed', () => {
        document.removeEventListener('keydown', escapeHandler)
      })
    }
  }
}
