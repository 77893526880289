
  import filter from 'lodash/filter'
  import isEmpty from 'lodash/isEmpty'

  import Column from '@/components/layout/Column'
  import Button from '~/components/general/elements/Button'
  import EventBus, { BusEvents } from '~/services/eventBus/EventBus'

  export default {
    name: 'BannerSwiper',
    components: {
      Button,
      Column
    },
    inject: ['getLoc'],
    props: {
      items: {
        type: Array,
        default: () => []
      }
    },
    data () {
      const oThis = this

      return {
        isSmallScreen: process.client ? window.innerWidth <= 991 : false,
        filteredItems: oThis.setFilteredItems(this.items),
        swiperOptions: {
          observer: true,
          observeParents: true,
          observeSlideChildren: true,
          loop: true,
          initialSlide: 0,
          watchOverflow: true,
          preloadImages: false,
          lazy: {
            loadOnTransitionStart: true
          },
          watchSlidesVisibility: true,
          preventInteractionOnTransition: true,
          roundLengths: true,
          pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
          },
          autoplay: {
            delay: 10000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }
        }
      }
    },
    mounted () {
      EventBus.$on(BusEvents.app.windowResize, this.windowSizeChanged)
      this.filteredItems = this.setFilteredItems(this.items)
    },
    methods: {
      hasContentValue(value) {
        return value && !isEmpty(value)
      },
      setFilteredItems(items) {
        const oThis = this
        return filter(items, banner => {
          return oThis.isSmallScreen ? banner.allowedOnMobile : banner.allowedOnDesktop
        })
      },
      showControls (vm) {
        return vm.items.length > 0
      },
      handleSwiperReadied () {
        // this.swiper = this.$refs.mySwiper.$swiper
      },
      next () {
        this.swiper.slideNext(500)
      },
      back () {
        this.swiper.slidePrev(500)
      },
      windowSizeChanged() {
        this.isSmallScreen = window.innerWidth <= 991
        this.filteredItems = this.setFilteredItems(this.items)
      },
      /**
       *
       * @param {CMSBanner} item
       */
      onSlideClick(item) {
        const hasButtonDesktop = this.hasContentValue(this.getLoc(item.buttonLoc))
        const hasButtonMobile = this.hasContentValue(this.getLoc(item.buttonMobileLoc))
        if (item.hasLink && (!hasButtonDesktop && window.innerWidth > 767) || (!hasButtonMobile && window.innerWidth <= 767)) {
          if(item.isLinkExternal) {
            window.open(item.link, '_blank').focus();
          } else {
            this.$router.push(item.link)
          }
        }
      }
    },
    beforeDestroy () {
      EventBus.$off(BusEvents.app.windowResize, this.windowSizeChanged)
    }
  }
