export default {
  data () {
    return {
      fonts: {
        encodeCond: 'Encode Sans Condensed',
        hind: 'Hind'
      }
    }
  }
}
