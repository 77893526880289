
  import Row from '@/components/layout/Row'

  export default {
    name: 'RatingInput',
    components: { Row },
    props: {
      value: Number,
      limit: {
        type: Number,
        default: 5
      }
    },
    data () {
      return {}
    },
    computed: {
      steps (vm) {
        return [...Array(5).keys()]
      }
    },
    methods: {}
  }
