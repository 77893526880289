

  import { FormField } from '~/structures/FormField'

  export default {
    name: 'RadioInput',
    components: {},
    props: {
      field: {
        type: FormField,
        required: true
      },
      value: {
        required: true
      }
    },
    data () {
      return {
        model: this.value
      }
    },
    computed: {
      id (vm) {
        return vm.field.name
      }
    },
    watch: {
      value: {
        immediate: true,
        handler () {
          this.model = this.value
        }
      }
    },
    methods: {
      switchTo (value) {
        this.model = value
        this.update(value)
      },
      update (value) {
        this.$emit('input', value)
      }
    }
  }
