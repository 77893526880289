
  import FormInput from '@/components/form/inputs/FormInput'
  import Button from '@/components/general/elements/Button'

  import ApiError from '@/structures/ApiError'
  import FormValidationManager from '@/structures/FormValidationManager'

  export default {
    name: 'GeneralForm',
    components: { FormInput, Button },
    props: {
      title: {
        type: String
      },
      error: {
        type: [ApiError, Error]
      },
      submitTitle: {
        type: String
      },
      modelType: {
        type: Object
      },
      sections: {
        type: Array,
        default: () => []
      },
      showSubmitButton: {
        type: Boolean,
        default: true
      },
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data () {
      const oThis = this

      return {
        model: oThis.modelType,
        validationManager: new FormValidationManager({
          model: oThis.modelType,
          sections: oThis.sections
        })
      }
    },
    computed: {
      status (vm) {
        let status = {
          text: null,
          color: 'red'
        }

        if (vm.error instanceof ApiError) {
          status = {
            text: vm.error.getMessage() || '',
            color: vm.error.getColor() || ''
          }
        } else if (vm.error) {
          status.text = vm.error.message
        }

        return status
      },
      hasAfterButtonSlot () {
        return 'afterButton' in this.$slots
      }
    },
    watch: {
      sections: {
        deep: true,
        handler(sections) {
          this.validationManager.updateSections(sections)
        }
      },
      modelType: {
        immediate: true,
        handler () {
          this.model = this.modelType
        }
      }
    },
    methods: {
      layout (word) {
        return word === 'half' ? '6' : '12'
      },
      input (value, fieldName) {
        this.model[fieldName] = value
        this.validationManager.updateModelValue(fieldName, value)

        if(fieldName === 'password') {
          this.validationManager.validateField('passwordConfirm')
        }
        if(fieldName === 'country') {
          this.validationManager.validateField('zip')
        }

        this.$emit('input', this.model)
      },
      isValid () {
        return this.validationManager.validate()
      },
      submit () {
        if (!this.validationManager.validate()) return

        this.$emit('submit', this.model)
      }
    }
  }
