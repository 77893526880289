
  // Button is a simple component displaying the button with js action or as a link
  // <br>Example:<br>
  // ![Button](./../docsAssets/Button-green.png)
  // @group Basic
  import ListLoadIndicator from "~/components/general/elements/ListLoadIndicator";

  export default {
    name: 'Button',
    components: { ListLoadIndicator },
    props: {
      // Title label
      title: {
        type: String,
        required: true
      },

      // The color/layout type of the button
      type: {
        // `String`: `green`, `blue`, `black`, `fb-blue`, `tw-blue`, `basket`
        type: String,
        default: 'green'
      },

      // Target link for redirect
      link: {
        type: [Object, String]
      },

      arrow: Boolean,
      arrowActive: Boolean,

      isExternalLink: Boolean,

      isSubmit: Boolean,
      disabled: Boolean,
      isLoading: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {}
    },
    computed: {
      isDisabled (vm) {
        return vm.disabled ? 'disabled' : ''
      }
    }
  }
