
  import Check from '@/components/general/elements/Check'
  import Asterisk from '@/components/general/elements/Asterisk'
  import { FormField } from '~/structures/FormField'

  export default {
    name: 'Checkbox',
    functional: true,
    components: { Asterisk, Check },
    props: {
      id: String,
      label: String,
      field: {
        type: FormField,
        required: false
      },
      value: {
        required: true
      },
      isLighter: Boolean,
      isDark: Boolean
    },
    getField (id, label) {
      return new FormField({
        id,
        label,
        type: 'checkbox'
      })
    },
    getListeners (listeners, value) {
      const newListeners = {}

      if ('input' in listeners) {
        newListeners.click = function () {
          listeners.input(!value)
        }
      }

      return newListeners
    }
  }
