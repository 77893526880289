

  export default {
    name: 'ActionLink',
    functional: true,
    components: {},
    props: {
      type: {
        type: String,
        default: 'green'
      },
      disabled: Boolean
    },
    isDisabled (disabled) {
      return disabled ? 'disabled' : ''
    }
  }
